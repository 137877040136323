const API_URL = "https://code.nile.solutions.api.codenilesolutions.com/api";

const get = (endpoint) => {
  return fetch(`${API_URL}/${endpoint}`)
    .then((res) => res.json())
    .then((res) => res)
    .catch((err) => "fetch error");
};

const post = (endpoint, body) => {
  const options = {
    headers: { "Content-Type": "application/json" },
    method: "post",
    body: JSON.stringify(body),
  };

  return fetch(`${API_URL}/${endpoint}`, options)
    .then((res) => res.json())
    .then((res) => res)
    .catch((err) => "fetch error");
};

export { get, post };
