import { Button, Image, Select } from "antd";
import myImage from "../assets/Icon 1.png";
import { Link } from "react-router-dom";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import AOS from "aos";

const OurWorks = () => {
  const works = [
    { title: "title", image: myImage },
    { title: "title2", image: myImage },
    { title: "title3", image: myImage },
    { title: "title3", image: myImage },
  ];

  AOS.init({
    duration: 1000,
  });

  const ourWorks = useSelector((state) => state.myReducer.our_works);

  return (
    <div className="min-h-[100vh] p-5 mt-5">
      <div className="flex justify-center">
        <div>
          <h1 className="text-4xl sofia-font text-[--cn-color1]">Our Works</h1>
        </div>
      </div>

      {ourWorks.map((work, index) => (
        <div className="w-[90%] min-h-[20rem] mx-auto mt-5 grid grid-cols-12 gap-y-2 bg-[--black] rounded-lg">
          {index % 2 === 0 && (
            <div
              className={
                index % 2 === 0
                  ? "col-span-8 min-h-[40rem] flex items-center justify-center bg-white"
                  : "col-span-4 min-h-[40rem] bg-white"
              }
              id={work.id}
            >
              <Image
                src={work.image_url}
                alt={work.title}
                className="w-[25rem] h-[25rem]"
              />
            </div>
          )}

          {index % 2 === 0 && (
            <div
              className={
                index % 2 === 0
                  ? "col-span-4 min-h-[40rem] flex flex-col justify-center items-center"
                  : "col-span-8 min-h-[40rem flex flex-col justify-center items-center"
              }
              id={work.id}
              data-aos="fade-up"
            >
              <h4 className="text-[--cn-color1] text-2xl">{work.title}</h4>
              <p className="text-center w-[80%] text-wrap sofia-font text-white mt-2">
                {work.description}
              </p>
              <Link className="mt-1 text-teal-400" to={`./${work.id}`}>
                <Button
                  type="link"
                  className="text-[--cn-color2] text-xl"
                  icon={<ArrowRightOutlined />}
                  iconPosition="end"
                >
                  More
                </Button>
              </Link>
            </div>
          )}

          {index % 2 !== 0 && (
            <div
              className={
                index % 2 === 0
                  ? "col-span-4 min-h-[40rem] flex flex-col items-center justify-center text-[--cn-color1]"
                  : "col-span-8 min-h-[40rem flex flex-col items-center justify-center text-[--cn-color1]"
              }
              data-aos="fade-up"
              id={work.id}
            >
              <h4 className="text-[--cn-color1] text-4xl">{work.title}</h4>
              <p className="text-center w-[80%] text-wrap sofia-font text-2xl text-white mt-2">
                {work.description}
              </p>
              <Link className="mt-1 text-teal-400" to={`./${work.id}`}>
                <Button
                  type="link"
                  className="text-[--cn-color2] text-xl"
                  icon={<ArrowRightOutlined />}
                  iconPosition="end"
                >
                  More
                </Button>
              </Link>
            </div>
          )}

          {index % 2 !== 0 && (
            <div
              className={
                index % 2 === 0
                  ? "col-span-8 min-h-[40rem] flex justify-center items-center bg-white"
                  : "col-span-4 min-h-[40rem flex justify-center items-center bg-white"
              }
              data-aos="fade-up"
              id={work.id}
            >
              <Image
                src={work.image_url}
                alt={work.title}
                className="w-[25rem] h-[25rem]"
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default OurWorks;
