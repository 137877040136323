
import React from "react";
import Items from "../Components/items";
import AOS from "aos";
import "aos/dist/aos.css";
import Features from "../Components/Features";
function Services() {
  AOS.init({
    duration: 1000, // Animation duration in milliseconds
  });
  const SItems = [
    {
      No: "01",
      src: "/Images/Icons/Icon 1.png",
      Name: "CUSTOM SOFTWARE DEVELOPMENT",
      Msg:"At CodeNile Solutions, we provide tailored software solutions designed to meet your unique business needs. Our custom software development services ensure that you get personalized, scalable, and efficient software that perfectly fits your operations."
    },
    {
      No: "02",
      src: "/Images/Icons/webdevIconfinal.png",
      Name: "WEBSITE DESIGN & DEVELOPMENT",
      Msg:"At CodeNile Solutions, we craft visually stunning, user-friendly websites that drive engagement and meet your business goals. Our experienced team offers end-to-end solutions, from custom design to responsive development and optimization. With a focus on quality, reliability, and collaboration, we deliver websites that elevate your online presence and drive results. Contact us today to get started."
    },
    {
      No: "03",
      src: "/Images/Icons/UIUX.png",
      Name: "UI/UX DESIGNING",
      Msg:"At CodeNile Solutions, we specialize in UI/UX design, creating intuitive and visually appealing digital experiences that delight users and drive conversions. Our dedicated team of designers combines creativity with user-centric design principles to craft interfaces that are both aesthetically pleasing and highly functional."
    },
    {
      No: "04",
      src: "/Images/Icons/DMarketingIcon.png",
      Name: "DIGITAL MARKETING",
      Msg:"At CodeNile Solutions, we offer comprehensive digital marketing solutions to help businesses thrive in the digital landscape. Our experienced team leverages the latest tools and strategies to drive brand awareness, engagement, and conversions."
    },
    {
      No: "05",
      src: "/Images/Icons/GDIcon.png",
      Name: "GRAPHIC DESIGN",
      Msg:"At CodeNile Solutions, we offer professional graphic design services to help businesses stand out in today's competitive market. Our creative team specializes in delivering visually appealing designs that communicate your brand message effectively."
    },
    {
      No: "06",
      src: "/Images/Icons/MoDeIcon.png",
      Name: "MOBILE & DESKTOP APP DEVELOPMENT",
      Msg:"At CodeNile Solutions, we specialize in creating innovative and user-friendly mobile and desktop applications that empower businesses and enhance user experiences. Our dedicated team of developers leverages the latest technologies to deliver robust and scalable solutions tailored to your specific requirements."
    },
  ];
  return (
    <div>
    <div className="flex flex-col gap-x-8 bg-[#0B131C] h-screen">
      <div className="flex flex-col gap-8">
        <div data-aos="zoom-in">
          <h1  className="text-6xl font-bold text-center text-[#FF7F00] ">
            Services
          </h1>
        </div>
        <div data-aos="fade-right">
          <h1 className="text-3xl font-bold text-center text-white">
            We are dedicated to delivering outstanding service to our customers.
          </h1>
        </div>
        <div className="p-2 lg:ml-20 lg:mr-20">
          <div
            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 ml-4 mr-4"
            data-aos="fade-up"
          >
            {SItems.map((Item) => (
              <Items
                key={Item.No}
                No={Item.No}
                src={Item.src}
                Name={Item.Name}
                Msg={Item.Msg}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
    <Features/>
    </div>
  );
}

export default Services;
