import React from "react";
import "../css/home.css";
import myvideo from "../assets/big_buck_bunny_720p_1mb.mp4";
import OurWork from "../Components/our_works";
import AboutUs from "../Components/about_us";
import WhatWeDo from "../Components/what_we_do";
import LetsWork from "../Components/lets_work";
import intro from "../assets/Intro.mp4";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
function Home() {
  const home_content = useSelector((state) => state.myReducer.home_content);
  const fetching = useSelector((state) => state.myReducer.fetching);
console.log('kk',home_content);
  return (
    <div className="min-h-[100vh] p-0">
      {!home_content.length == 0 ? (
        <div className="w-full h-full">
          <div className="p-0 video-container h-[100vh]">
            <video
              className="video opacity-55 "
              
              loop
              muted
              autoPlay={true}
              playsInline
              width={"100%"}
              height={"100%"}
            >
              {/* <source src={home_content[0].front_video_url} type="video/mp4" /> */}
              <source src={intro} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="content sofia-font text-[2.5rem] md:text-[5rem] flex flex-col justify-center items-center">
              <p className="text-ite text-white">{home_content[0].motto}</p>
              <div className="md:flex-row flex flex-col justify-between items-center">
                <Link className="flex no-underline" to={"/services"}>
                  <button classwhName="serv_btn text-[0.5rem]">Our Services</button>
                </Link>
                <Link className="flex no-underline " to={"/our-works"}>
                  <button className="wrk_btn text-[1rem] mt-2 md:mt-0 md:ms-3 hover:bg-blue-400 animate-bounce">
                   <span className="no-underline hover:text-white ">Our Works</span> 
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <OurWork />
          <WhatWeDo />
          <AboutUs />
          <LetsWork />
        </div>
      ) : (
        <div className="mx-auto w-[10rem]  h-[30rem] flex justify-center items-center">
          <div className="loader"></div>
        </div>
      )}
    </div>
  );
}

export default Home;
