import { configureStore, createSlice } from "@reduxjs/toolkit";

const myReducer = createSlice({
  name: "myReducer",
  initialState: {
    value: [],
    our_works: [],
    home_content: [],
    about_us: [],
    fetching: { our_works: true, home_content: true, about_us: true  },
  },
  reducers: {
    setValue: (state, action) => {
      state.value = action.payload;
    },
    setWorks: (state, action) => {
      state.our_works = action.payload;
    },
    setHomeContent: (state, action) => {
      state.home_content = action.payload;
    },
    setAboutUs:(state, action)=>{
      state.about_us = action.payload;
    },
    setFetching: (state, action) => {
      state.fetching = action.payload;
    },
  },
});

const store = configureStore({
  reducer: {
    myReducer: myReducer.reducer,
  },
});

export const { setValue, setWorks, setFetching, setHomeContent, setAboutUs } =
  myReducer.actions;
export default store;
