import React, { useState } from "react";
import { Button, Modal } from "antd";

function Items({ No, src, Name, Msg }) {
  const [readmore, setReadmore] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div
      onMouseEnter={() => setReadmore(true)}
      onMouseLeave={() => setReadmore(false)}
    >
      <div className="bg-slate-600 rounded-lg h-full hover:bg-[#FF7F00] hover:text-white font-bold text-xl md:text-4xl text-gray-300 p-4">
        <div className="flex gap-1 justify-between p-2 lg:p-4 sm:gap-8 md:m-2">
          <h1 className="text-6xl">{No}</h1>
          <img className="" src={src} width={90} alt={Name} />
        </div>
        <h1 className="hover-group:text-[#FF7F00] sm:text-xl md:text-2xl lg:text-3xl">
          {Name}
        </h1>
        {readmore && (
          <div className="text-white">
            <Button
              onClick={showModal}
              className="bg-blue-400 rounded-lg group-hover:bg-blue-800"
            >
              <p className=" text-sm p-1">Read More</p>
            </Button>
            <Modal
              title={Name}
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              centered
           // width={1000}
              // style={{ width: "100%" }} // Adjust the width percentage as needed
            >
              <p className="text-[#1A7FC3] text-xl border-2 border-[#1A7FC3] p-2">{Msg}</p>
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
}

export default Items;
