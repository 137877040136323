import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import Senay from "../assets/about us/images/senaya.png";
import Mihretu from "../assets/about us/images/MihretuE.png";
import Fanuel from "../assets/about us/images/Fanuel.jpg";
import Fanu from "../assets/about us/images/Fanuu.jpg"
import Dagim from "../assets/about us/images/DagimA.png";
import "./style.css";
import Twitter from "../assets/about us/images/twitter.png";
import Facebook from "../assets/about us/images/facebook.png";
import Instagram from "../assets/about us/images/instagram.png";
import Linkedin from "../assets/about us/images/linkedin.png";
import { Link } from "react-router-dom";

export default function TeamMember() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className="h-screen ">
      <div>
        <div class="font-sans">
          <div class="max-w-8xl mx-auto">
            <div data-aos="fade-left" class="text-center">
              <h2 class="font-extrabold text-[#FF7F00] text-6xl ">
                Meet our team
              </h2>
            </div>

            <div data-aos="fade-up" class="grid sm:grid-cols-4 gap-8 max-sm:justify-center mt-12 max-sm:max-w-xs mx-auto p-2">
              <div class="bg-gray-800 p-4 border rounded-lg">
                <img
                  src={Senay}
                  class="w-full object-contain object-top rounded-lg"
                />

                <div class="text-center mt-4">
                  <h4 class="text-base font-semibold text-white">
                    Software Developer 
                  </h4>
                  <p class="text-xs mt-2 text-white">Senay Alemneh</p>
                </div>
                
              </div>

              <div class="bg-gray-800 p-4 border rounded-lg">
                <img
                  src={Mihretu}
                  class="w-full object-contain object-top rounded-lg"
                />

                <div class="text-center mt-4">
                  <h4 class="text-base font-semibold text-white">
                  Software Developer
                  </h4>
                  <p class="text-xs mt-2 text-white">Mihretu Enideshaw</p>
                </div>
              </div>

              <div class="bg-gray-800 p-4 border rounded-lg">
                <img
                  src={Dagim}
                  class="w-full object-contain object-top rounded-lg"
                />

                <div class="text-center mt-4">
                  <h4 class="text-base font-semibold text-white">
                  Software Developer
                  </h4>
                  <p class="text-xs mt-2 text-white">Dagim Assefa</p>
                </div>
              </div>

              <div class="bg-gray-800 p-4 border rounded-lg">
                <img
                  src={Fanu}
                  class="w-full object-contain object-top rounded-lg"
                />

                <div class="text-center mt-4">
                  <h4 class="text-base font-semibold text-white">
                  Software Developer
                  </h4>
                  <p class="text-xs mt-2 text-white">Fanuel Amare</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container team " data-aos="fade-up" data-aos-delay="100">
        <div className="row d-flex  flex-wrap justify-content-between ">
          {[
            {
              img: Senay,
              name: "Senay Alemneh",
              role: "Lead Graphics Designer",
              description:
                "Lead designer with a passion for creative solutions.",
            },
            {
              img: Mihretu,
              name: "Mihretu Endeshaw",
              role: "Chief Executive Officer",
              description: "Dedicated to driving growth and success.",
            },
            {
              img: Dagim,
              name: "Dagim Assefa",
              role: "Managing Director",
              description: "Focused on operational excellence and leadership.",
            },
            {
              img: Fanuel,
              name: "Fanuel Amare",
              role: "Chief Executive Officer",
              description: "Visionary leader with a focus on innovation.",
            },
          ].map((member, index) => (
            <div
              className="col-xl-6  col-md-8  mb-4  col-sm-12"
              key={index}
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div className="team-member ">
                <div className="member-img ">
                  <img
                    src={member.img}
                    className="img-fluid"
                    alt={member.name}
                  />
                </div>
                <div className="member-info">
                  <div className="social">
                    <Link to="">
                      <img
                        className="bi bi-twitter-x"
                        src={Twitter}
                        alt="Twitter"
                      />
                    </Link>
                    <Link to="">
                      <img
                        className="bi bi-facebook"
                        src={Facebook}
                        alt="Facebook"
                      />
                    </Link>
                    <Link to="">
                      <img
                        className="bi bi-instagram"
                        src={Instagram}
                        alt="Instagram"
                      />
                    </Link>
                    <Link to="">
                      <img
                        className="bi bi-linkedin"
                        src={Linkedin}
                        alt="LinkedIn"
                      />
                    </Link>
                  </div>
                  <section className="rounded w-72 sharehold">
                    <h4>{member.name}</h4>
                    <span>{member.role}</span>
                    <p>{member.description}</p>
                  </section>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div> */}
    </div>
  );
}
