import logo from "../assets/logo.png";
const WorkMark = () => {
  return (
    <div className="flex">
      <img src={logo} className="h-16" alt="CodeNile logo" />
      <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
        <span className="text-[#1A7FC3] font-bold">CodeNile</span>{" "}
        <span className="text-[#FF7F00] font-bold">Solutions</span>
      </span>
    </div>
  );
};

export default WorkMark;
