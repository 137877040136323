import React, { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/home";
import About from "./pages/about";
import Services from "./pages/services";
import Contact from "./pages/contact";
import ErrorPage from "./pages/errorpage";
import MainLayout from "./pages/mainlayout";
import { get } from "./_services/api_services";
import { setWorks, setFetching, setHomeContent, setAboutUs } from "./store/store";
import { useDispatch, useSelector } from "react-redux";
import OurWorks from "./pages/our_works";
import OurWork from "./pages/our_work";
function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <MainLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "about",
          element: <About />,
        },
        {
          path: "services",
          element: <Services />,
        },
        {
          path: "our-works",
          element: <OurWorks />,
        },
        {
          path: "our-works/:id",
          element: <OurWork />,
        },
        {
          path: "contact",
          element: <Contact />,
        },
      ],
    },
  ]);

  const dispatch = useDispatch();
  const fetching = useSelector((state) => state.myReducer.fetching);
  const getWorks = async () => {
    const works = await get("works");
    dispatch(setWorks(works));
    dispatch(setFetching({ ...fetching, our_works: false }));
  };

  const getHomeContents = async () => {
    const home_content = await get("main");
    dispatch(setHomeContent(home_content));
    dispatch(setFetching({ ...fetching, home_content: false }));
  };
const getAboutUs = async()=>{
  const about_us = await get("about-us");
  dispatch(setAboutUs(about_us));
  dispatch(setFetching({...fetching, about_us: false}));
};
  useEffect(() => {
    getWorks();
    getHomeContents();
    getAboutUs();
  }, []);

  return <RouterProvider router={router} />;
}

export default App;
