import React,{useEffect} from 'react'
import styled from 'styled-components';
import Asset1 from '../assets/about us/images/man2.png';
import Asset2 from '../assets/about us/images/women.jpg'
import { Link } from 'react-router-dom';
import Contact from './contact'
import Screen from '../assets/about us/images/screen.png'
import Carouser from '../Components/carouser_effect'
import Aos from 'aos';
import 'aos/dist/aos.css'
import Dagim from '../assets/about us/images/Dagim.jpg'
import Fanuel from '../assets/about us/images/Fanuel.jpg'
import Senay from '../assets/about us/images/Senay.jpg'
import Mihretu from '../assets/about us/images/me.jpg'
import TeamMember from '../Components/team_member';



const Nile = styled.div`
  width: 100%;
  height: auto;
`;

const Badge = styled.h3`
  background-color: #ff7f00;
  color: white;
  font-family: Avant Garde Medium BT;
  font-size: 2rem;
  font-weight: 600;
  border: none;
  border-radius: none;
  text-decoration: none;
  margin: 5rem;
  height: auto;
`;

const Aboutus = styled.h1`
  color: #ff7f00;
  font-family: Avant Garde Medium BT;
  font-size: 5.4rem;
`;

const ButtonL = styled.button`
  color: #ff7f00;
  background-color: #ff7f00;
  font-family: Avant Garde Medium BT;

  &:hover {
    background-color: #0c6e31;
    text-decoration: none;
  }

  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }

  [data-aos="bounce"] {
    animation: bounce 2s ease-in-out;
  }
`;

const CardContainer = styled.div`
  position: relative;
  width: 18rem;
  overflow: hidden;
  height: 20rem
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0d1f34;
  
  .card-img-top {
    display: block;
    width: 100%;
    height: 100%;
    transition: transform 0.2s ease 0.5s;
  }


  
  .card-body {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    background: #0d1f34;
    text-align: center;
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 1s ease, transform 0.3s ease 0.5s;
    padding: 10px;
    margin-bottom:2rem;
    color: white;
  }

&:hover .card-img-top {
  transform: scale(0.5,0.5) translate(0%, -45%);
  border-radius: 70%
  }

  &:hover .card-body {
    opacity: 1;
    transform: translateY(0) translate(0%, -20%);
  }
`;



const  About = ()=> {

  useEffect(()=>{
    Aos.init({
      duration: 1000,
    });
  }, []);

  return (
    <>
      <Nile className="overflow-hidden">
        <div data-aos>
          <div className="row justify-content-start">
            <div className="col-lg-6 col-m-12 d-flex flex-col justify-content-start align-items-center">
              <Badge className="badge hover:bg-[#1A7FC3]" data-aos="fade-up">
                <span className="text-lg ">
                  Where Technology Flows, Creativity Grows – CodeNile Solutions.
                </span>
              </Badge>
              <Aboutus
                data-aos="fade-right"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
                About Us
              </Aboutus>
              <p
                data-aos="fade-left"
                data-aos-anchor="#example-anchor"
                data-aos-offset="500"
                data-aos-duration="500"
                className="text-wrap"
                style={{
                  color: "white",
                  width: "80%",
                  fontFamily: "Avant Garde Medium BT",
                  fontSize: "1.65rem",
                }}
              >
                CodeNile Solutions: Where creativity meets technology.
                Specializing in graphics design and web development, we bring
                visions to life with precision and innovation. Elevate your
                brand and digital presence with our expert craftsmanship and
                passion for excellence.
              </p>
              <button className="border-2 border-white p-2 rounded-lg bg-[#1A7FC3] hover:bg-[#FF7F00]">
                <Link
                  to="/contact"
                  className="contact text-decoration-none font-bold text-white "
                >
                  {" "}
                  Contact Us{" "}
                </Link>
              </button>
            </div>
            <div className="col-lg-6 col-md-12 md:mt-5">
              <img
                src={Asset1}
                alt="image-jig"
                className="d-block w-100 p-20 rounded-circle animate-spin-slow"
              />
            </div>
          </div>
        </div>
      </Nile>
      <div className="h-100 w-100">
      <div className="row" style={{ maxHeight: '100vh' }}>
        <div className="col-lg-6 col-md-12 d-flex align-items-center justify-content-center pt-md-5">
          <img src={Screen} alt="Screen" className="animate-bounce" />
        </div>
        <div  data-aos="fade-up"
          className="col-lg-6 d-flex flex-column justify-content-start align-items-center col-sm-12 pb-sm-5 mb-md-5"
          style={{ marginTop: '12rem', marginBottom: '6rem' }}
        >
          <Aboutus>Our Values</Aboutus>
          <p
            className="text-wrap col-md-12"
            style={{
              color: 'white',
              width: '80%',
              fontFamily: 'Avant Garde Medium BT',
              fontSize: '1.65rem',
            }}
          >
            We pride ourselves in being fully upfront and transparent with all of our clients. Everything we do is documented, and we hold ourselves to high standards when it comes to the worlds of design and development. As we create better experiences for your users, we strive to set you up for success by giving you the tools you need.
          </p>
        </div>
      </div>
    </div>

      <div className=" ">
        <TeamMember />
      </div>
        
      <Carouser/>
    </>
  );
};

export default About;
