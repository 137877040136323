import { Link } from "react-router-dom";
import solution from "../assets/about us/undraw_problem_solving_re_4gq3.svg";
import { Button } from "antd";
import { BiRightArrow } from "react-icons/bi";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
const AboutUs = () => {
  const home_content = useSelector((state) => state.myReducer.home_content);

  return (
    <div
      data-aos="fade-up"
      className="bg-white grid grid-cols-12 bg-[--black] mt-5 min-h-[20rem] p-5"
    >
      <div className="col-span-6 bg-[--black] text-white sofia-font p-3 flex flex-col justify-center items-center">
        <h4 className="text-4xl">About Us</h4>
        <p className="text-center mt-2 w-[60%] text-xl">
          {home_content[0].about_us.short_description}
        </p>
        <Link className="mt-2 text-teal-400" to={"/about"}>
          <Button
            type="link"
            className="text-teal-400 text-xl"
            icon={<ArrowRightOutlined />}
            iconPosition="end"
          >
            More About Us
          </Button>
        </Link>
      </div>
      <div className="col-span-6 bg-white flex justify-center items-center border">
        <img src={solution} className="w-[80%] h-[80%] " alt="solution" />
      </div>
    </div>
  );
};

export default AboutUs;
