import { Alert, Button, Input, message } from "antd";
import { BsTelegram } from "react-icons/bs";
import { post } from "../_services/api_services";
import { useEffect, useState } from "react";
import { object, string, number, date, InferType, ref } from "yup";

const LetsWork = () => {
  const [input, setInput] = useState({
    name: "",
    subject: "",
    message: "",
    email: "",
  });

  const [status, setStatus] = useState({
    inputs: { error: "", loading: false },
  });

  const sendEmail = () => {
    post("send-email", input)
      .then((res) => {
        setStatus({ ...status, inputs: { ...status.inputs, loading: false } });
        setInput({ name: "", email: "", message: "", subject: "" });
        message.success("Message Sent Successfully, Thank You!");
      })
      .catch((err) => {
        console.log("err", err);
        setStatus({ ...status, inputs: { ...status.inputs, loading: false } });
        setInput({ name: "", email: "", message: "", subject: "" });
        message.error("Some Error Occurred, Please Try again.");
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(input);
    setStatus({ ...status, inputs: { ...status.inputs, loading: true } });
    const isInputValid = await validateInput();
    console.log("valid", isInputValid);
    if (!isInputValid) {
      setStatus({ ...status, inputs: { ...status.inputs, loading: false } });
      return;
    }
    sendEmail();
  };

  const validateInput = async () => {
    let mySchema = object({
      name: string().required("Your Name is required"),
      subject: string().required("Message Subject is required"),
      email: string()
        .email("Invalid Email Format")
        .required("Your Email is required")
        .min("8", "Please Enter at least 10 characters."),
      message: string()
        .required("Your Message is required")
        .min("8", "Please Enter at least 10 Message characters."),
    });

    return mySchema
      .validate(input)
      .then((res) => {
        setStatus({ ...status, inputs: { ...status.inputs, error: "" } });
        return true;
      })
      .catch((err) => {
        setStatus({
          ...status,
          inputs: {
            ...status.inputs,
            error: err.toString(),
            loading: false,
          },
        });
        return false;
      });
  };

  return (
    <div data-aos="fade-up" className="bg-white grid grid-cols-12 mt-[5rem] min-h-[20rem] p-5">
      <div className="col-span-6 p-[3rem] border">
        <form
          className="flex flex-col justify-center p-2"
          onSubmit={handleSubmit}
        >
          <div className="grid gap-2 grid-cols-12 justify-center">
            <Input
              placeholder="Name"
              className="text-xl col-span-6 border-white rounded-none focus:border-white hover:border-white focus:border-b-teal-700 border-b-teal-700 border-b-2"
              value={input.name}
              name="name"
              onChange={handleChange}
            />

            <Input
              placeholder="Email"
              className="text-xl col-span-6 border-white rounded-none focus:border-white hover:border-white focus:border-b-teal-700 border-b-teal-700 border-b-2"
              value={input.email}
              name="email"
              onChange={handleChange}
            />
          </div>

          <Input
            placeholder="Subject"
            className="mt-3 text-xl border-white rounded-none focus:border-white hover:border-white focus:border-b-teal-700 border-b-teal-700 border-b-2"
            value={input.subject}
            name="subject"
            onChange={handleChange}
          />

          <Input.TextArea
            placeholder="Message"
            className="mt-2 text-xl border-white rounded-none focus:border-white hover:border-white focus:border-b-teal-700 border-b-teal-700 border-b-2"
            value={input.message}
            name="message"
            onChange={handleChange}
          />

          {status.inputs.error && (
            <Alert
              type="error"
              className="mt-3 text-center"
              message={status.inputs.error}
            />
          )}

          <Button
            icon={<BsTelegram />}
            className="cool-btn mt-5 bg-[--cn-color1] text-white w-[7rem] h-[3rem] text-xl mx-auto"
            htmlType="submit"
            loading={status.inputs.loading}
          >
            Send
          </Button>
        </form>
      </div>
      <div className="col-span-6 text-center sofia-font flex flex-col justify-center items-center bg-[--black]">
        <p className="text-4xl text-[--cn-color2]">Let's Work Together!</p>
        <p className="text-lg mt-3 w-[70%] text-white">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Corrupti
          aspernatur ratione at suscipit a inventore quisquam iste. Voluptas,
          quis architecto!
        </p>
      </div>
    </div>
  );
};

export default LetsWork;
