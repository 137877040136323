import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Card } from "antd";
import { CgDesignmodo, CgIfDesign, CgWebsite } from "react-icons/cg";
import { GrMoney } from "react-icons/gr";
import { ImImage } from "react-icons/im";
import { MdOutlineWeb } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const WhatWeDo = () => {
  const home_content = useSelector((state) => state.myReducer.home_content);

  const short_description =
    "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptates, est facere natus autem ex ab in nam officia blanditiis ut.";

  let services = [];
  home_content[0].what_we_do.services.map((work) => {
    services.push({
      title: work,
      icon: work == "Service 1" ? <CgWebsite size={20} color="white" /> : "",
      desc_className: work == "Service 1" ? "text-2xl text-white" : "",
      card_className: work == "Service 1" ? "bg-teal-500 shadow-xl" : "",
    });
  });

  return (
    <div
      data-aos="fade-up"
      className="bg-white w-full grid grid-cols-12 min-h-[25rem] mt-5 p-5 font-[Avant Garde Medium BT]"
    >
      <div className="col-span-5 flex justify-center items-center border-l-teal-300 border-l-[1rem] rounded-l-[1rem]">
        <div className="flex flex-col justify-center items-center">
          <p className="text-4xl ps-2 text-teal-800 text-center">What We Do</p>
          <p className="mt-2 w-[50%] ps-3 text-xl text-center">
            {home_content[0].what_we_do.short_description}
          </p>
          <Link className="mt-1 text-teal-400" to={"/services"}>
            <Button
              type="link"
              className="text-green-800 text-xl"
              icon={<ArrowRightOutlined />}
              iconPosition="end"
            >
              Learn More
            </Button>
          </Link>
        </div>
      </div>
      <div className="col-span-2"></div>
      <div className="col-span-5 grid grid-cols-12 gap-2 p-2  shadow">
        {services.length > 0 &&
          services.map((service) => (
            <div className="col-span-full md:col-span-6">
              <Card className={service.card_className}>
                {service.icon}
                <p className={service.desc_className}>{service.title}</p>
              </Card>
            </div>
          ))}

        {/* <div className="col-span-full md:col-span-6">
          <Card className="bg-teal-500 shadow-xl">
            <CgWebsite size={20} color="white" />
            <p className="text-2xl text-white">Website Solutions</p>
          </Card>
        </div>
        <div className="col-span-full md:col-span-6">
          <Card className="shadow-xl">
            <ImImage size={20} color="orange" />
            <p className="text-2xl text-orange-400">Graphics Design</p>
          </Card>
        </div>
        <div className="col-span-full md:col-span-6">
          <Card className="shadow-xl">
            <CgIfDesign size={20} color="red" />
            <p className="text-2xl text-red-600">UI/UX</p>
          </Card>
        </div>
        <div className="col-span-full md:col-span-6">
          <Card className="bg-slate-700 shadow-xl">
            <GrMoney size={20} color="white" />
            <p className="text-2xl text-white">Digital Marketing</p>
          </Card>
        </div> */}
      </div>
    </div>
  );
};

export default WhatWeDo;
