import { Outlet } from "react-router-dom";
import Navbar from "../Components/navbar";
import Footer from "../Components/footer";

const MainLayout = () => {
  return (
    <div className="w-full main-layout h-full flex flex-col">
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
};

export default MainLayout;
