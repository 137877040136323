import React, {useEffect} from 'react';
import {Carousel} from 'antd'
import './style.css'
import Aos from 'aos';
import 'aos/dist/aos.css'
import { useSelector, useDispatch } from 'react-redux';


const contentStyle = {
  height: '28rem',
  width: '35%',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  
};


const CarouselComponent = ()=>{
  
  useEffect(()=>{
    Aos.init({
      duration: 1000,
    })
  },[])


    const about_us = useSelector((state) => state.myReducer.about_us);
    const fetching = useSelector((state)=> state.myReducer.fetching);
  

 
const baseUrl = "https://code.nile.solutions.api.codenilesolutions.com/"


return (
  <div className='mt-5  '>
    <h1 data-aos="fade-up" className=' text-center p-10 ' style={{fontFamily:'Avant Garde Medium BT',fontSize: '5.4rem',color: "#FF7F00"}}>Our Works</h1>
  <Carousel autoplay className="m-10  d-flex justify-content-center align-items-center  shadow-slate-500 ">
  {
    about_us.map(
      (data , index)=>{ 

          const cleanedImagePath = data.image_paths.replace(/"/g, '');
          const imageURL = `${baseUrl}${cleanedImagePath}`;
          console.log('Image URL:', imageURL);


          return (
              <div className='key={index} d-flex justify-content-center p-5 '   style={{backgroundColor: "#1A7FC3"}} >
                <img 
                  style={contentStyle}  
                  id='projectimage' 
                  src={imageURL} 
                  className='rounded-circle' 
                  alt={data.title}
                />
            <section id='discreption'  className=' text-white ml-4 d-flex justify-content-center align-items-center flex-col  '>
            <h3 style={{ width:"20rem"}} >{data.title} </h3>
            <p className=' text-wrap ' style={{marginLeft: "7rem", width: "30rem"}}  >
                {data.description}
            </p>
            </section>
        </div>
          )
        
        }
    )
  }
    
  </Carousel>
  </div>
  )
}
export default CarouselComponent;