import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import WordMark from "./wordmark";
import { useState } from "react";
export default function Navbar() {
  const navLinks = [
    { title: "Home", route: "/" },
    { title: "About", route: "/about" },
    { title: "Services", route: "/services" },
    { title: "Contact Us", route: "/contact" },
  ];

  const [showNav, setShowNav] = useState(false);

  return (
    <div className="shadow-xl sofia-font px-5 bg-white">
      <nav className="">
        <div className="w-[95%] flex flex-wrap items-center justify-between mx-auto p-2">
          <Link
            to="/"
            className="flex items-center space-x-3 rtl:space-x-revers no-underline"
          >
            <WordMark />
          </Link>

          <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse md:hidden">
            <button
              data-collapse-toggle="navbar-cta"
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              data-twe-collapse-init
              data-twe-target="#navbarSupportedContent1"
              onClick={() => {
                setShowNav(!showNav);
              }}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>

          <div
            className={
              showNav
                ? "items-center justify-between md:flex md:w-auto md:order-1 w-full"
                : "items-center justify-between md:flex md:w-auto md:order-1 hidden"
            }
            id="navbarSupportedContent1"
            data-twe-collapse-item
          >
            <ul className="flex flex-col font-medium p-1 md:p-0 mt-4  rounded-lg md:space-x-10 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 ">
              {navLinks.map((link) => (
                <NavLink
                  to={`${link.route}`}
                  // className="text-lg block py-2 px-3 md:p-0 text-white hover:text-blue-700 active:underline"
                  
                  className={({ isActive }) =>
                    !isActive
                      ? "text-[--cn-color2] text-lg py-2 px-3 md:p-0 no-underline"
                      : "text-[--cn-color1] text-lg py-2 px-3 md:p-0  underline-offset-8 decoration-[#FF7F00] no-underline"
                  }
                >
                  {link.title}
                </NavLink>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
