import image1 from "../assets/logo.png";
import image2 from "../assets/Icon 1.png";
import { Carousel, Image, Skeleton, Spin } from "antd";
import {
  BiChevronLeftCircle,
  BiChevronRightCircle,
  BiPencil,
} from "react-icons/bi";
import { useEffect, useState } from "react";
import work_image_1 from "../assets/logo.png";
import work_image_2 from "../assets/Icon 1.png";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { useSelector } from "react-redux";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const OurWork = () => {
  AOS.init({
    duration: 1000,
  });

  const ourWorks = useSelector((state) => state.myReducer.our_works);
  const fetching = useSelector((state) => state.myReducer.fetching);

  const [currentContent, setCurrentContent] = useState(0);

  useEffect(() => {
    if (!fetching.our_works && ourWorks.length > 0) {
      setCurrentContent(ourWorks[0].image_url);
    }
  }, [fetching.our_works]);

  const handleSwipe = (e) => {
    setCurrentContent(ourWorks[e].image_url);
  };

  return (
    <div
      className="min-h-[20rem] grid grid-cols-12 mt-5 p-5"
      data-aos="fade-up"
    >
      <div className="col-span-6 bg-white flex justify-center items-center border">
        <Spin spinning={ourWorks.length == 0} delay={500}>
          <Image
            src={currentContent}
            alt="work image"
            className="w-full !h-[20rem]"
          />
        </Spin>
      </div>

      <div class="w-full col-span-6 h-full flex flex-col md:p-[5rem] p-[1rem] bg-[--black]">
        <div className="w-full h-[3rem] text-4xl text-white">Our Works</div>

        <Skeleton
          active
          className="skeleton-paragraph-1"
          loading={ourWorks.length == 0}
        >
          <Carousel
            arrows
            className="bg-parent w-full min-h-[20rem]"
            letArrow={<BiChevronLeftCircle color="white" size={"50px"} />}
            rightArrow={<BiChevronRightCircle className="w-full" />}
            autoplay
            afterChange={handleSwipe}
          >
            {ourWorks.length > 0 &&
              ourWorks.map((content) => (
                <div className="text-white text-[1.2rem] sofia-font p-3 md:p-0">
                  {/* <p className="tracking-widest">{content.type}</p> */}
                  <p className="text-4xl text-[#FF7F00]">{content.title}</p>
                  <p className="text-slate-300">{content.description}</p>
                  <div className="p-2 md:p-5 flex flex-col md:flex-row w-[80%] md:w-[80%] justify-center mx-auto mt-5">
                    <Link to={"/our-works/1"} className="flex no-underline">
                      <button className="cool-btn p-1 md:p-3 !bg-[#FF7F00] !text-white">
                        Case Study
                      </button>
                    </Link>
                    <Link to={"/our-works"} className="flex no-underline">
                      <button className="cool-btn p-1 md:p-3 mt-2 md:mt-0 md:ms-3 bg-white text-[#1A7FC3] border-[2px] border-solid">
                        All Our Work
                      </button>
                    </Link>
                  </div>
                </div>
              ))}
          </Carousel>
        </Skeleton>
      </div>
    </div>
  );
};

export default OurWork;
